* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans';
}

.paragraph p,
.paragraph h2,
.paragraph h3
{
  margin-top:1em;
}